
.paginationContainer {
  display: flex;
  align-items: center;

  ::v-deep .el-pagination {
    display: flex;
    align-items: center;
  }
  @mixin button() {
    background: #ffffff;
    border-radius: 6px;
    width: 35px;
    height: 35px;
    background: #ffffff;
    // border-radius: 4px;
    border: 1px solid #dadada;
  }
  @mixin textStyle() {
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    text-align: center;
    color: #101010;
  }
  @mixin liTextStyle() {
    font-size: 16px;
    line-height: 35px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #101010;
  }
  .jumpText {
    @include textStyle;
  }
  input {
    border: 0px;
    background-color: none;
    outline: none;
  }
  input:focus {
    outline: none;
  }
  .jumpInput {
    @include textStyle;
    width: 38px;
    height: 38px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #dadada;
    margin-left: 10px;
  }

  .ye {
    @include textStyle;
    margin-left: 10px;
  }
  .go {
    width: 38px;
    height: 38px;
    text-align: center;
    line-height: 38px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #dadada;
    margin-left: 10px;
    cursor: default;
  }
  .go :active {
    transform: scale(0.94);
  }
  ::v-deep .btn-prev {
    @include button;
    padding-right: 0px;
    padding-left: 0px;
  }
  ::v-deep .btn-next {
    @include button;
    margin-left: 10px;
    padding-right: 0px;
    padding-left: 0px;
  }
  // ::v-deep .el-icon-arrow-left:before {
  //   content: "上一页";
  //   @include textStyle;
  // }
  // ::v-deep .el-icon-arrow-right:before {
  //   content: "下一页";
  //   @include textStyle;
  //   padding-left: unset;
  // }
  ::v-deep .el-pager li {
    margin-left: 10px;
    width: 35px;
    height: 35px;
    background: #ffffff;
    border-radius: 6px;
    // padding: 12px 0;
    text-align: center;
    // border: 1px solid #dadada;
    @include liTextStyle;
  }
  ::v-deep .el-pager li.active {
    @include liTextStyle;
    color: #ffffff;
    background: #2885FF;
  }
}
