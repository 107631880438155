
.Search {
  width: 100%;
  /* border: 1px solid #f2f2f2; */
  border-radius: 5px;
  margin-bottom: 20px;
  /* padding: 20px 40px; */
}
.searchBox {
  text-align: right;
}
.searchBox > .el-input {
  width: 220px;
}
.level > p {
  font-size: 24px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: bold;
  color: #101010;
  margin: 20px 0;
}
.active {
  background-color: #14569f;
  color: #ffffff;
}
// 搜索框
.search-container-wrapper {
  width: 100%;
  height: 80px;
  background-color: #f2f2f2;

  .search-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #101010;
      line-height: 24px;
    }

    input {
      width: 600px;
      height: 46px;
      background: #ffffff;
      border-radius: 6px;
      border: 2px solid #14569f;
      margin-right: 14px;
      font-size: 23px;
      padding-left: 10px;
    }

    input::placeholder {
      padding-left: 7px;
      font-size: 18px;
      color: #999;
    }

    button {
      width: 120px;
      height: 46px;
      background: #14569f;
      border-radius: 4px;
      color: #ffffff;
      border: none;
      cursor: pointer;
      font-size: 18px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;

      i {
        margin-right: 14px;
      }
    }
  }
}
